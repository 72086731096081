
const Item = ({ url }: { url: string }) => <a href={url} target="_blank" className="d-block mb-2">{url}</a>


const links = [
  'https://www.ieftinel.ro/termeni-si-conditii',
  'https://www.ieftinel.ro/gdpr',
  'https://www.ieftinel.ro/despre-noi',
  'https://www.ieftinel.ro/confidentialitate',
  'https://www.ieftinel.ro/magazine-ieftinel',
  'https://anpc.ro/ce-este-sal/',
  'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=EN'
]

export const ContactScreen = () => {
  return <div className="text-center" style={{ paddingTop: 100, paddingBottom: 80, paddingLeft: 4, paddingRight: 4 }}>
    {links.map((link, key) => <Item key={String(key)} url={link} />)}
  </div>
}