import ro from '../locales/ro.json';

type RO = keyof typeof ro;

export const useFormatMessage = () => {

  const formatMessage: (key: RO) => string = (key) => {
    return ro[key];
  }

  return { formatMessage }
}