import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useAppContext } from "../../context/appContext";

const INFO_MENU = <NavLink to="/contact" className={({ isActive }) =>
  isActive ? "active-nav" : ""
}><i className="fa fa-info"></i><span><FormattedMessage id="footer.contact" /></span></NavLink>

export const Footer = () => {
  const { token } = useAppContext();

  if (!token) {
    return <div id="footer-bar" className="footer-bar-1">
      <NavLink to="login" className={({ isActive }) =>
        isActive ? "active-nav" : ""
      }><i className="fa fa-user"></i><span>
          <FormattedMessage id="footer.login" /></span></NavLink>
      <NavLink to="register" className={({ isActive }) =>
        isActive ? "active-nav" : ""
      }><i className="fa fa-user-plus"></i><span><FormattedMessage id="footer.register" /></span></NavLink>
    </div>
  }

  return <div id="footer-bar" className="footer-bar-1">
    <NavLink to="/" className={({ isActive }) =>
      isActive ? "active-nav" : ""
    }><i className="fa fa-barcode"></i><span><FormattedMessage id="footer.coupons" /></span></NavLink>
    {INFO_MENU}
    <NavLink to="/cont" className={({ isActive }) =>
      isActive ? "active-nav" : ""
    }><i className="fa fa-sign-out"></i><span><FormattedMessage id="footer.cont" /></span></NavLink>
  </div>
}