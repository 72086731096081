import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ASSET_URL } from "../../../const";
import { useFormatMessage } from "../../../hooks/useFormatMessage";

export interface CouponProps {
  id: number;
  image: string;
  bar_code: string;
  isUsed: boolean;
  isActive: boolean;
  onConfirm?: (id: number) => void
}

export const Coupon = ({ image, bar_code, id, onConfirm, isUsed }: CouponProps) => {
  const { formatMessage } = useFormatMessage();

  const onSubmit = () => {
    if (window.confirm(formatMessage("coupon.confirm-modal"))) {
      withReactContent(Swal).fire({
        confirmButtonText: formatMessage("coupon.confirm-button-done"),
        confirmButtonColor: '#203d78',
        html: <img src={`${ASSET_URL}/${bar_code}`} className="img-fluid" alt="cupon" />
      }).then(() => onConfirm && onConfirm(id))
    }
  }

  return <div className="card card-style">
    <img src={`${ASSET_URL}/${image}`} className={`img-fluid ${isUsed ? "card-grayscale-image" : ""}`} alt="cupon" />
    <div className="content pb-0">
      <button className={`btn btn-s ${isUsed ? "bg-dark" : "bg-green-dark"} rounded-sm font-700 text-uppercase w-100`} disabled={isUsed} onClick={onSubmit}>{formatMessage("coupon.confirm-button")}</button>
    </div>
  </div>
}