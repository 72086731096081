import { FormattedMessage } from "react-intl";
import axios from "axios";
import { useState } from "react";

import { useAppContext } from "../../context/appContext";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import { AuthWrapper } from "../../components";
import { API_URL } from "../../const";

export const ContScreen = () => {
  const { logout, email } = useAppContext();
  const { formatMessage } = useFormatMessage();
  const [password, setPassword] = useState('')

  const onLogout = () => {
    if (window.confirm(formatMessage('footer.logout-confirm-modal')) && logout) {
      logout();
    }
  }

  const onUnsubscribe = () => {
    if (window.confirm(formatMessage('cont.unsubscribe-newsletter-confirm'))) {
      axios.post(API_URL + '/unsubscribe', { email }).then((res) => {
        if (res.data.status === 'success') {
          return window.alert(formatMessage('cont.unsubscribe-newsletter-success'));
        }
      })
        .catch((e) => console.error(e))
    }
  }

  const onDeleteUser = () => {
    if (window.confirm(formatMessage('cont.delete-user-confirm'))) {
      axios.post(API_URL + '/user-destroy', { email, password }).then((res) => {
        if (res.data.status === 'success' && logout) {
          return logout();
        }
      })
        .catch((e) => console.error(e))
    }
  }

  return <AuthWrapper>

    <button className="back-button btn btn-full btn-m shadow-large rounded-sm text-uppercase font-700 bg-pink2-dark w-100 mb-3" onClick={onLogout}><FormattedMessage id="footer.logout" /><i className="fa fa-sign-out ms-2"></i></button>

    <button className="back-button btn btn-full btn-m shadow-large rounded-sm text-uppercase font-700 bg-warning w-100 mb-3" onClick={onUnsubscribe}><FormattedMessage id="cont.unsubscribe-newsletter" /><i className="fa fa-envelope ms-2"></i></button>

    <button className="back-button btn btn-full btn-m shadow-large rounded-sm text-uppercase font-700 w-100" onClick={onDeleteUser}><FormattedMessage id="cont.delete-user" /><i className="fa fa-user-slash ms-2"></i></button>

  </AuthWrapper>
}