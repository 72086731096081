import { Navigate } from "react-router-dom";
import { useAppContext } from "../../context/appContext";

interface Props {
  children: JSX.Element
}

export const PublicRoute = ({ children }: Props) => {
  const { token } = useAppContext();

  if (token) {
    return <Navigate to="/" replace />;
  }

  return children;
}